import * as Sentry from '@sentry/nextjs'

import PawIcon from 'components/svg/paw.svg'
import Router from 'next/router'
import styled from 'styled-components'
import { useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div`
  height: 100vh;
  max-width: ${(props) => props.theme?.layout?.maxWidth};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme?.colors?.primaryBackground || 'white'};
`

const Title = styled.div`
  margin-top: -20vh;
  font-size: 100px;
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0;
  white-space: nowrap;
`

const StyledIcon = styled(PawIcon)`
  display: inline-block;
  fill: ${(props) => props.theme.colors.primary};
  height: 60px;
  margin: 0 5px;
`

const Description = styled.div`
  padding: 25px;
  text-align: center;
`

const Button = styled.button`
  height: 60px;
  border-radius: 25px;
  margin-top: 15px;
  border: 2px solid ${(props) => props.theme.colors.primary};
  font-size: 17px;
  background: ${(props) => props.theme.colors.primary};
  padding: 0 25px;
  cursor: pointer;
  font-weight: 400;
  color: white;
`

const Custom404 = () => {
  const { t } = useTranslation('common')

  useEffect(() => {
    Sentry.captureException(new Error('404: ' + Router.asPath))
  }, [])

  return (
    <Wrapper>
      <Title>
        4
        <StyledIcon />4
      </Title>
      <Description>{t('PAGE_NOT_FOUND')}</Description>
      <Button onClick={() => Router.push('/')}>{t('HOME')}</Button>
    </Wrapper>
  )
}

export default Custom404
